<template>
  <div>
    <div class="card shadow">
      <form autocomplete="off">
        <div class="pt-4 pb-4 pe-4 ps-4" @keyup.enter="$emit('getAlboList')">
          <div class="row pb-4">
            <div class="col-sm-4 filter">
              <SelectInput
                :options="albi_tipo"
                name="albi_tipo"
                placeholder="Tipo albo"
                :value="idTipoAlbo"
                @changeSelect="
                  resetIdCategoriaAlbo();
                  setIdTipoAlbo($event);
                "
              />
            </div>
            <div class="col-sm-4 filter">
              <SelectInput
                :options="categorieAlbo"
                name="categorieAlbo"
                placeholder="Categoria"
                :value="idCategoriaAlbo"
                @changeSelect="setIdCategoriaAlbo($event)"
                :disabled="!idTipoAlbo"
              />
            </div>
            <div class="col-sm-4 filter">
              <SelectInput
                :options="qualificheAlbo"
                name="qualificheAlbo"
                placeholder="Qualifica"
                :value="idQualificaAlbo"
                @changeSelect="setIdQualificaAlbo($event)"
                :disabled="!idCategoriaAlbo"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 filter">
              <input
                type="text"
                class="form-control"
                placeholder="Cognome"
                aria-label=""
                :value="cognomeAlbo"
                @input="setCognomeAlbo"
              />
            </div>
            <div class="col-sm-4 filter">
              <input
                type="text"
                class="form-control"
                placeholder="Nome"
                aria-label=""
                :value="nomeAlbo"
                @input="setNomeAlbo"
              />
            </div>

            <div class="col-sm-4 filter">
              <input
                type="text"
                class="form-control"
                placeholder="Codice SNM"
                :value="codiceSnmAlbo"
                @input="setCodiceSnmAlbo"
              />
            </div>
          </div>
          <div class="text-center mt-5">
            <span
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFilterAlbo"
              aria-expanded="false"
              aria-controls="collapseExample"
              class="badge rounded-pill bg-light text-gray-600"
              @click="isActive = !isActive"
              >Filtri avanzati
              <i
                :class="{
                  'fas fa-angle-up fs-6 text-gray-600': isActive,
                  'fas fa-angle-down fs-6 text-gray-600': !isActive,
                }"
              >
              </i
            ></span>
            <button
              v-if="!isActive"
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="$emit('getAlboList')"
              :disabled="!loaded"
            >
              Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
            </button>
            <span
              v-if="!isActive"
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="
                $emit('resetFilters');
                resetCategorie();
                resetQualfiche();
              "
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>
          <div class="collapse" id="collapseFilterAlbo">
            <div class="bg-light rounded-3">
              <div class="row mt-4 pb-4 pt-4 ps-2 pe-2">
                <div class="col-sm-3 filter">
                  <Datepicker
                    v-model="dataAcqDaAlbo"
                    @update:modelValue="setDataAcqDaAlbo($event)"
                    placeholder="Data acquisizione da"
                    :format="format"
                    :monthChangeOnScroll="false"
                    :enableTimePicker="false"
                    inputClassName="h-28"
                    autoApply
                  />
                </div>
                <div class="col-sm-3">
                  <Datepicker
                    v-model="dataAcqAAlbo"
                    @update:modelValue="setDataAcqAAlbo($event)"
                    placeholder="Data acquisizione a"
                    :format="format"
                    :monthChangeOnScroll="false"
                    :enableTimePicker="false"
                    inputClassName="h-28"
                    autoApply
                  />
                </div>
                <div class="col-sm-3 filter">
                  <Datepicker
                    v-model="dataDecDaAlbo"
                    @update:modelValue="setDataDecDaAlbo($event)"
                    placeholder="Data decorrenza da"
                    :format="format"
                    :monthChangeOnScroll="false"
                    :enableTimePicker="false"
                    inputClassName="h-28"
                    autoApply
                  />
                </div>
                <div class="col-sm-3">
                  <Datepicker
                    v-model="dataDecAAlbo"
                    @update:modelValue="setDataDecAAlbo($event)"
                    placeholder="Data decorrenza a"
                    :format="format"
                    :monthChangeOnScroll="false"
                    :enableTimePicker="false"
                    inputClassName="h-28"
                    autoApply
                  />
                </div>
              </div>
              <div class="row ps-2 pe-2">
                <div class="col-sm-3 filter">
                  <Datepicker
                    v-model="dataFineDaAlbo"
                    @update:modelValue="setDataFineDaAlbo($event)"
                    placeholder="Data fine da"
                    :format="format"
                    :monthChangeOnScroll="false"
                    :enableTimePicker="false"
                    inputClassName="h-28"
                    autoApply
                  />
                </div>
                <div class="col-sm-3">
                  <Datepicker
                    v-model="dataFineAAlbo"
                    @update:modelValue="setDataFineAAlbo($event)"
                    placeholder="Data fine a"
                    :format="format"
                    :monthChangeOnScroll="false"
                    :enableTimePicker="false"
                    inputClassName="h-28"
                    autoApply
                  />
                </div>
                <div class="col-sm-6 filter">
                  <div class="dp__input_wrap text-gray-600">
                    <input
                      placeholder="Società"
                      name="denominazione_societa"
                      type="text"
                      class="form-control"
                      @input="
                        inputFocus = 'societa';
                        getSocieta($event.target.value);
                      "
                      :value="societaDenominazioneAlbo"
                      autocomplete="off"
                    />
                    <svg
                      @click="setIdSocietaAlbo({ name: null, value: null })"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      class="dp__icon dp__clear_icon dp__input_icons"
                    >
                      <path
                        d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                      ></path>
                      <path
                        d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                      ></path>
                    </svg>
                  </div>
                  <ListSocieta
                    :focusOnInput="inputFocus === 'societa'"
                    :list="societa"
                    @hideList="
                      inputFocus = null;
                      resetSocieta;
                    "
                    @selectedSocieta="
                      resetSocieta();
                      setIdSocietaAlbo($event);
                      inputFocus = null;
                    "
                  ></ListSocieta>
                </div>
              </div>
              <div class="row ps-2 pt-4 pe-2">
                <div class="col-sm-3 filter">
                  <SelectInput
                    :options="statoLookup"
                    name="statoLookup"
                    placeholder="Stato Pagamenti"
                    :value="statoAlbo"
                    @changeSelect="setStatoAlbo($event)"
                  />
                </div>
                <div class="col-sm-3 filter">
                  <SelectInput
                    :options="statoLookup"
                    name="statoLookup"
                    placeholder="Stato Tesseramento"
                    :value="statoTesseramentoAlbo"
                    @changeSelect="setStatoTesseramentoAlbo($event)"
                  />
                </div>
                <div class="col-sm-3 filter">
                  <SelectInput
                    :options="statoLookup"
                    name="statoLookup"
                    placeholder="Stato Aggiornamento"
                    :value="statoAggiornamentoAlbo"
                    @changeSelect="setStatoAggiornamentoAlbo($event)"
                  />
                </div>
                <div class="col-sm-3 filter">
                  <SelectInput
                    :options="statoUtenzaLookup"
                    name="statoUtenzaLookup"
                    placeholder="Stato Utenza"
                    :value="statoUtenzaAlbo"
                    @changeSelect="setStatoUtenzaAlbo($event)"
                  />
                </div>
              </div>
              <div class="row px-2 pt-4 pb-4">
                <div class="col-sm-3 mb-4">
                  <label class="ps-2">Ultimo pagamento albo:</label>
                  <SelectInput
                    :options="stagioni"
                    name="stagionePagamentiDa"
                    placeholder="Stagione da"
                    :value="stagionePagamentoDa"
                    @changeSelect="setStagionePagamentoDa($event)"
                  />
                </div>
                <div class="col-sm-3 mb-4">
                  <label></label>
                  <SelectInput
                    :options="stagioni_pagamenti_a"
                    name="stagionePagamentiA"
                    placeholder="Stagione a"
                    :value="stagionePagamentoA"
                    @changeSelect="setStagionePagamentoA($event)"
                    :disabled="!stagionePagamentoDa"
                  />
                </div>
                <div class="col-sm-3 mb-4">
                  <label class="ps-2">Pagamento mai effettuato:</label>
                  <div class="pt-2">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :checked="pagamentiNonRegistrati == true"
                      @click="
                        pagamenti_non_registrati = !pagamenti_non_registrati;
                        setPagamentiNonRegistrati(pagamenti_non_registrati);
                      "
                    />
                  </div>
                </div>
                <div class="col-sm-3 filter">
                  <label></label>
                  <SelectInput
                    placeholder="Comitato"
                    :options="comitati"
                    :value="idComitatoRegionaleAlbo"
                    name="id_periferico"
                    @changeSelect="setComitato($event)"
                  />
                </div>
              </div>
            </div>
            <div class="text-center mt-4">
              <button
                v-if="isActive"
                type="button"
                data-bs-toggle=""
                data-bs-target=""
                aria-expanded="false"
                aria-controls=""
                class="badge rounded-pill bg-light text-gray-600 ms-4"
                @click="$emit('getAlboList')"
                :disabled="!loaded"
              >
                Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
              </button>
              <span
                v-if="isActive"
                type="button"
                data-bs-toggle=""
                data-bs-target=""
                aria-expanded="false"
                aria-controls=""
                class="badge rounded-pill bg-light text-gray-600 ms-4"
                @click="
                  $emit('resetFilters');
                  resetCategorie();
                  resetQualfiche();
                "
              >
                <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
              ></span>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  ref,
  watch,
  getCurrentInstance,
  onMounted,
} from "vue";
import { useStore } from "vuex";
import ListSocieta from "../utility/ListSocieta.vue";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import Datepicker from "vue3-date-time-picker";
import { format } from "@/composables/formatDate";
import "vue3-date-time-picker/dist/main.css";

export default defineComponent({
  name: "filtriALBO",
  emits: ["resetFilters", "getAlboList"],
  props: {
    resetted: {
      type: Boolean,
    },
  },
  components: {
    ListSocieta,
    SelectInput,
    Datepicker,
  },
  setup(props) {
    const isActive = ref(false);
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const albi_tipo = computed(() =>
      store.getters.getStateFromName("albi_tipo")
    );
    const keys = ref("atcq");
    if (!albi_tipo.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const nomeAlbo = computed(() => store.getters.nomeAlbo);
    const setNomeAlbo = (e) => {
      const string = e.target.value;
      store.commit("setNomeAlbo", string);
    };

    const cognomeAlbo = computed(() => store.getters.cognomeAlbo);
    const setCognomeAlbo = (e) => {
      const string = e.target.value;
      store.commit("setCognomeAlbo", string);
    };

    const codiceSnmAlbo = computed(() => store.getters.codiceSnmAlbo);
    const setCodiceSnmAlbo = (e) => {
      const string = e.target.value;
      store.commit("setCodiceSnmAlbo", string);
    };

    const dataAcqDaAlbo = computed(() => store.getters.dataAcqDaAlbo);
    const setDataAcqDaAlbo = (event) => {
      store.commit("setDataAcqDaAlbo", event);
    };
    const dataAcqAAlbo = computed(() => store.getters.dataAcqAAlbo);
    const setDataAcqAAlbo = (event) => {
      store.commit("setDataAcqAAlbo", event);
    };
    const dataDecDaAlbo = computed(() => store.getters.dataDecDaAlbo);
    const setDataDecDaAlbo = (event) => {
      store.commit("setDataDecDaAlbo", event);
    };
    const dataDecAAlbo = computed(() => store.getters.dataDecAAlbo);
    const setDataDecAAlbo = (event) => {
      store.commit("setDataDecAAlbo", event);
    };
    const dataFineDaAlbo = computed(() => store.getters.dataFineDaAlbo);
    const setDataFineDaAlbo = (event) => {
      store.commit("setDataFineDaAlbo", event);
    };
    const dataFineAAlbo = computed(() => store.getters.dataFineAAlbo);
    const setDataFineAAlbo = (event) => {
      store.commit("setDataFineAAlbo", event);
    };
    //societa
    const societaDenominazioneAlbo = computed(
      () => store.getters.societaDenominazioneAlbo
    );
    const idSocietaAlbo = computed(() => store.getters.idSocietaAlbo);
    const setIdSocietaAlbo = ({ value, name }) => {
      store.commit("setIdSocietaAlbo", value);
      store.commit("setSocietaDenominazioneAlbo", name);
    };
    const getSocieta = (string) => {
      if (string === "") {
        store.commit("emptySocietaNomeCodAff");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getSocietaNomeCodAff", string);
    };
    const resetSocieta = () => {
      store.commit("emptySocietaNomeCodAff");
    };
    //
    const verificaAlbo = computed(() => store.getters.verificaAlbo);
    const setVerificaAlbo = (event) => {
      store.commit("setVerificaAlbo", event);
    };
    const statoAlbo = computed(() => store.getters.statoAlbo);
    const setStatoAlbo = (event) => {
      store.commit("setStatoAlbo", event);
    };
    const statoAggionramentoAlbo = computed(
      () => store.getters.statoAggionramentoAlbo
    );
    const statoUtenzaAlbo = computed(() => store.getters.statoUtenzaAlbo);
    const setStatoUtenzaAlbo = (event) => {
      store.commit("setStatoUtenzaAlbo", event);
    };

    const categorieAlbo = ref([]);
    const idTipoAlbo = computed(() => store.getters.idTipoAlbo);
    const resetIdCategoriaAlbo = () => {
      store.commit("setIdCategoriaAlbo", null);
    };
    const setIdTipoAlbo = (event) => {
      if (!albi_tipo.value) return;
      const selecteTipoId = event != "" ? parseInt(event) : null;
      store.commit("setIdTipoAlbo", selecteTipoId);
      categorieAlbo.value.splice(0, categorieAlbo.value.length);
      qualificheAlbo.value.splice(0, qualificheAlbo.value.length);
      albi_tipo.value.forEach((element) => {
        if (element.id === selecteTipoId) {
          element.categorie.forEach((cat) => {
            categorieAlbo.value.push(cat);
          });
        }
      });
    };

    const qualificheAlbo = ref([]);
    const idCategoriaAlbo = computed(() => store.getters.idCategoriaAlbo);
    const setIdCategoriaAlbo = (event) => {
      const selecteCatId = event != "" ? parseInt(event) : null;
      store.commit("setIdCategoriaAlbo", selecteCatId);
      qualificheAlbo.value.splice(0, qualificheAlbo.value.length);
      categorieAlbo.value.forEach((element) => {
        if (element.id === selecteCatId) {
          element.qualifiche.forEach((qual) => {
            qualificheAlbo.value.push(qual);
          });
        }
      });
    };

    const resetCategorie = () => {
      categorieAlbo.value.splice(0, categorieAlbo.value.length);
    };

    const idQualificaAlbo = computed(() => store.getters.idQualificaAlbo);
    const setIdQualificaAlbo = (event) => {
      store.commit("setIdQualificaAlbo", event);
    };

    const resetQualfiche = () => {
      qualificheAlbo.value.splice(0, qualificheAlbo.value.length);
    };

    const inputFocus = ref(null);

    watch(
      () => props.resetted,
      (value) => {
        if (value) {
          resetCategorie();
          resetQualfiche();
        }
      }
    );

    onMounted(() => {
      if (
        dataAcqDaAlbo.value ||
        dataAcqAAlbo.value ||
        dataDecDaAlbo.value ||
        dataDecAAlbo.value ||
        dataFineDaAlbo.value ||
        dataFineAAlbo.value ||
        societaDenominazioneAlbo.value ||
        statoUtenzaAlbo.value
      ) {
        isActive.value = true;
        document.getElementById("collapseFilterAlbo").classList.add("show");
      }
    });

    setIdTipoAlbo(idTipoAlbo.value);
    setIdCategoriaAlbo(idCategoriaAlbo.value);

    const setStatoAggiornamentoAlbo = (event) => {
      store.commit("setStatoAggiornamentoAlbo", event == 2 ? 0 : event);
    };
    const statoTesseramentoAlbo = computed(
      () => store.getters.statoTesseramentoAlbo
    );
    const setStatoTesseramentoAlbo = (event) => {
      store.commit("setStatoTesseramentoAlbo", event == 2 ? 0 : event);
    };

    const stagioni = computed(() => store.getters.stagioni);
    const stagioni_pagamenti_a = ref([]);

    const stagionePagamentoDa = computed(
      () => store.getters.stagionePagamentoDa
    );
    const setStagionePagamentoDa = (event) => {
      store.commit("setStagionePagamentoDa", event);
      store.commit("setStagionePagamentoA", null);
      stagioni_pagamenti_a.value = [];
      stagioni.value.forEach((element) => {
        if (element.id >= stagionePagamentoDa.value) {
          stagioni_pagamenti_a.value.push(element);
        }
      });
    };
    const stagionePagamentoA = computed(() => store.getters.stagionePagamentoA);
    const setStagionePagamentoA = (event) => {
      store.commit("setStagionePagamentoA", event);
    };

    const pagamentiNonRegistrati = computed(
      () => store.getters.pagamentiNonRegistrati
    );
    const setPagamentiNonRegistrati = (event) => {
      console.log(event);
      store.commit("setPagamentiNonRegistrati", event);
    };

    const comitati = computed(() => store.getters.getStateFromName("comitati"));
    const keysComitati = ref("c");
    if (!comitati.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keysComitati.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const idComitatoRegionaleAlbo = computed(
      () => store.getters.idComitatoRegionaleAlbo
    );
    const setComitato = (event) => {
      store.commit("setIdComitatoRegionaleAlbo", event);
    };

    return {
      inputFocus,
      categorieAlbo,
      resetCategorie,
      resetQualfiche,
      isActive,
      nomeAlbo,
      setNomeAlbo,
      cognomeAlbo,
      setCognomeAlbo,
      codiceSnmAlbo,
      setCodiceSnmAlbo,
      idTipoAlbo,
      setIdTipoAlbo,
      idCategoriaAlbo,
      setIdCategoriaAlbo,
      idQualificaAlbo,
      setIdQualificaAlbo,
      dataAcqDaAlbo,
      setDataAcqDaAlbo,
      dataAcqAAlbo,
      setDataAcqAAlbo,
      dataDecDaAlbo,
      setDataDecDaAlbo,
      dataDecAAlbo,
      setDataDecAAlbo,
      dataFineDaAlbo,
      setDataFineDaAlbo,
      dataFineAAlbo,
      setDataFineAAlbo,
      getSocieta,
      resetSocieta,
      idSocietaAlbo,
      setIdSocietaAlbo,
      societaDenominazioneAlbo,
      societa: computed(() => store.getters.societaNomeCodAff),
      verificaAlbo,
      setVerificaAlbo,
      statoAlbo,
      setStatoAlbo,
      statoUtenzaAlbo,
      setStatoUtenzaAlbo,
      qualificheAlbo,
      albi_tipo,
      trueFalseLookup: [
        { id: true, label: "Si" },
        { id: false, label: "No" },
      ],
      statoLookup: [
        { id: 1, label: "In regola" },
        { id: 2, label: "Non in regola" },
      ],
      statoUtenzaLookup: [
        { id: 1, label: "Non presente" },
        { id: 2, label: "Bloccata" },
        { id: 3, label: "Attiva" },
      ],
      format,
      loaded: computed(() => store.getters.getStateFromName("loadedalbo_list")),
      resetIdCategoriaAlbo,

      stagioni_pagamenti_a,
      stagioni,
      setStagionePagamentoDa,
      setStagionePagamentoA,
      stagionePagamentoDa,
      stagionePagamentoA,
      setPagamentiNonRegistrati,
      pagamentiNonRegistrati,
      statoAggionramentoAlbo,
      setStatoAggiornamentoAlbo,
      statoTesseramentoAlbo,
      setStatoTesseramentoAlbo,
      setComitato,
      idComitatoRegionaleAlbo,
      comitati,
    };
  },
});
</script>

<style></style>
